export const greatQuotes = [
  `“If we lose freedom here, there’s no place to escape to. This is the last stand on earth…We’ll preserve for our children this, the last best hope of man on earth, or we’ll sentence them to take the last step into a thousand years of darkness.” - Ronald Reagan`,
  `"If Jesus had never lived, we would not have been able to invent him." - Walter Wink`,
  `"The only true barrier to truth is the presumption that you already have it." - Chuck Missler`,
  `“I would unite with anybody to do right and with nobody to do wrong." - Frederick Douglass`,
  `"Your limitations are where your growth begins" - Jon Foreman`,
  `"The greatest teacher failure is" - Yoda`,
  `"Is it time for a 3rd party? It's time for a second party."`,
  `"There's an easy way to stop terrorism. Stop participating in it." - Noam Chomsky`,
  `"The meaning of life is to find your gift, The purpose of life is to give it away." - David Viscott`,
  `"Shall we expect some transatlantic military giant, to step the Ocean, and crush us at a blow? Never! All the armies of Europe, Asia, and Africa combined… could not by force, take a drink from the Ohio, or make a track on the Blue Ridge, in a trial of a thousand years. At what point then is the approach of danger to be expected? I answer, if it ever reach us, it must spring up amongst us. It cannot come from abroad. If destruction be our lot, we must ourselves be its author and finisher. As a nation of freemen, we must live through all time, or die by suicide." - Abe Lincoln`,
  `"First solve the problem. Then write the code" - John Johnson`,
  `"If you don't read the paper you're uninformed. If you do read the paper you're misinformed." - Denzel Washington`,
  `"The only things stopping me today are: genetics, lack of will, income, brain chemistry, and external events" - Eddie Pepitone`,
  `"Localization is the cure for polarization" - Adam Kokesh`,
  `"Be relentless in your iterations. Be ruthlessly honest in your assessments." - Greg Starling`,
  `"Producing beautiful software is not a goal. Solving complex technical problems is not a goal. Writing bug-free code is not a goal. Using sexy programming languages is not a goal. Add revenue. Reduce costs. Those are your only goals."`,
  `"There is no instance of a country having benefited from prolonged warfare" - Sun Tzu`,
  `"Contributing to maintain an army at a distance causes the people to be impoverished" - Sun Tzu`,
  `"Science progresses one funeral at a time"`,
  `"Life was made for everyone and love is the prize" - Tim Bergling`,
  `"Peace, commerce, and honest friendship with all nations; entangling alliances with none." - Thomas Jefferson`,
  `"Your future is whatever you make it - Emmit Brown (Doc)`,
  `"Anybody can make their future whatever they want it to be - Emmit Brown (Doc)`,
  `"I'm not here to prove what women can do. I'm just here to win." - Nikki Van Schyndel`,
  `"Goebbels was in favor of free speech for views he liked. So was Stalin. If you’re really in favor of free speech, then you’re in favor of freedom of speech for precisely the views you despise. Otherwise, you’re not in favor of free speech" -Noam Chomsky`,
  `"I prefer Dangerous Freedom over peaceful slavery" - Thomas Jefferson`,
  `"Once weapons were manufactured to fight wars, now wars are manufactured to sell weapons" - Arundhati Roy`,
  `"The performance you should worry about is the performance of the wrinkled wad of protein between your ears. Computers are orders of magnitude cheaper and faster than humans. Complexity is far more dangerous than poor runtime performance." - Floyd May`,
  `"Keep learning and you'll go far" - Adam Booth`,
  `"You can't have the fruits without the roots" - Stephen R. Covey`,
  `"A long, healthy, and happy life is the result of making contributions, of having meaningful projects that are personally exciting and contribute to and bless the lives of others." - Stephen R. Covey`,
  `"To keep progressing, we must learn, commit, and do—learn, commit, and do—and learn, commit, and do again." - Stephen R. Covey`,
  `"Your economic security does not lie in your job; it lies in your own power to produce—to think, to learn, to create, to adapt." - Stephen R. Covey`,
  `"Sometimes when I consider what tremendous consequences come from little things...I am tempted to think...there are no littel things" - Bruce Barton`,
  `"Service is the rent we pay for the privilege of living on this earth" - N. Eldon Tanner`,
  `"640 kilobytes ought to be enough for anybody" - Bill Gates`,
  `"By 2005 or so, it will become clear that the internet's impact on the economy has been no greater than the fax machine's" - Paul Krugman`,
  `"If you are building a server, I can't imagine using anything other than Go" - Ryan Dahl (creator of Node.js)`,
  `"To improve is to change; to be perfect is to change often" - Winston Churchill`,
  `"If you don't like something, change it. If you can't change it, change your attitude" - Maya Angelou`
]
export const goodQuotes = [
  `"It's better to be trusted than to be liked" - Stephen R. Covey`,
  `"We are not human beings having a spiritual experience. We are spiritual beings having a human experience" - Teilhard de Chardin`,
  `"It's fear that can't be trusted" - Elsa`,
  `"Look around. There's no experts here." - Ryan Kelley`,
  `"If goods stop crossing borders armies will" - Ron Paul`,
  `“The first thing I’m going to tell my successor, is watch the generals, to avoid feeling that just because they’re military men, their opinions on military matters are worth a damn.” - JFK`,
  `"He who would trade liberty for some temporary security deserves neither liberty nor securty. If we restrict liberty to attain security we will lose both" - Ben Franklin`,
  `"if we keep letting the government gain more power during a crisis then what's to stop them from creating a crisis to gain more power?" - Luke Rudkowski`,
  `"failure is success if we learn from it" - Malcolm Forbes`,
  `"if they knew what we were up to they would chase us down the street and hang us" - George H.W. Bush`,
  `"A tyranny sincerely exercised for the good of its victims may be the most oppressive. It would be better to live under robber barons than under omnipotent moral busybodies...who torment us for our own good...without end...with the approval of their own conscience" - C.S. Lewis`,
  `“Success is not final, failure is not fatal, it is the courage to continue that counts.” -Winston Churchhill`,
  `"I'm not here to hear comforting lies. I want to hear uncomfortable, unsettling truths." - Sam Tripoli`,
  `"Anybody can make their future whatever they want it to be - doc (emmit brown)`,
  `"Bread is the paper of the food industry. You write your sandwich on it" - Dwight Shrute`,
  `"I don't want to be a conspiracy theorist but I'm not an idiot either" - Jimmy Dore`,
  `"Those who control the past control the future, and those who control the present control the past." - George Orwell`,
  `"Democracy is two wolves and a sheep voting for what's for dinner"`,
  `"I'm not a bigot, but I am confused" - Joe Rogan`,
  `"Code is read and maintained more than it is written." - Avery Roswell`,
  `"Perhaps it is a universal truth that there loss of liberty at home is to be charged against provisions against danger, real or pretended, from abroad" - James Madison 1798`,
  `"Be fearful when others are greedy and be greedy when others are fearful" - Warren Buffet`,
  `"The best way to ensure that lucky things happen is to make sure a lot of things happen" - Mr. Peabody`,
  `"Don't criticize what you can't understand" - Bob Dylan`,
  `"It's been a great fun" - Scotty Pippin`,
  `"Morgan Freeman said it best. When asked how to stop racism. His immediate answer was 'Stop talking about it.'" - Klockwerks`,
  `"I don't care what anybody believes as long as it makes them a better person" - John batbour`,
  `"When the second amendment...they were using muskets. Yeah because they were fighting muskets!" - Sam Tripoli`,
  `"Democrats will give trillions to a death industry while you can't pay rent at home during an economic depression" - Jimmy Dore`,
  `"Showing up for work as a centrist at an American newspaper should not require bravery." - Bari Weiss`,
  `"the only redeeming quality if a liberal is they are not a leftist and the only redeeming quality of a leftist is that they are not a liberal"- Will Grig`,
  `"Honest conflict is better than dishonest harmony" - Joe Rogan`,
  `"One side wants to take 37% of my money and spend it on things I'm not interested in, things I'm against. The other side wants to take 34% of my money and spend it on things I'm not interested in, things I'm against" - Dave Smith`,
  `"Y'all I'm gonna die here! I'm gonna die here, man!...Tell my kids I love them. I'm dead...Ah! man! ah, ah-heh. I'm in pain. Please, please! Please I can't breath. Please man! ah ah-heh ahah. plese man. Please. I can't breath. I can't breath. Ah-aha, Ah-hah. I'm about to die. Man, I can't breath-my face, it's killing me. uh. I can't breath. Please I need...I can't breath...My stomach hurt, my neck hurt. everything hurtin. Ah. need some water or somethin. Please. Please. ah-hah. I can't breath officer-aaah. aaah. Gonna kill me. Their gonna kill me out here. Mama, mama, I cannot breath. I cannot breath.  They gonna kill me. I can't breath. ah-huh. Pleeease. Please!. I can't breath." - Goerge Floyd`,
  `"Riots are the language of the uheard" - MLK`,
  `"The CIA and the mafia are one organization who keep a low profile while they control the whole world as a business" - Chuck Giancana`,
  `"If voting could change anything it would be made illegal!" - Robert S. Borden, M.D.`,
  `"the Constitution is not under a stay-home order and the Bill of Rights cannot be quarantined.” - Kansas Attorney General Derek Schmidt`,
  `"If there is any one secret of success, it lies in the ability to get the other person's point of view and see things from that person's angle as well as from your own." - Henry Ford`,
  `"If you want to improve, be content to be thought foolish and stupid.” - Epictetus`,
  `"Government overhypes a threat as an excuse to grab more of our freedoms. When the threat is over, however, they never give us our freedoms back" - Ron Paul`,
  `"when 'happiness' is not necessarily a factor, sometimes kids, and parents, seem to end up happier." - Barbra Oakley`,
  `“I have no visibility into who the bad guys are” - Donald Rumsfeld sep. 8, 2003`,
  `“The history of military conflict in Afghanistan [has] been one of initial success, followed by long years of floundering and ultimate failure. We’re not going to repeat that mistake.” — President George W. Bush April 7, 2002`,
  `"I have figured out how to monetize the social justice warriors." - Jordan Peterson`,
  `"The America over which Roosevelt presided in 1940 was in its eleventh year of depression. No decline in American history had been so deep, so lasting, so far reaching." - Doris Kearns Goodwin`,
  `"if someone says something bad about Muslims, they draw more heat than someone who bombs Muslims" - Dave Smith`,
  `"if the American people allow private banks to control the issuance of their currency, first by inflation and then by deflation, the banks and corporations that will grow up around them will deprive the people of all their property until their children grow up homeless in the continent their fathers concerred.`,
  `"Teaching knowledge is indoctrination" - Barbery Oakley`,
  `"we've been in Afghanistan for longer than world war I and world war II combined and have nothing to show for it except for one legged vets" - Dave Smith`,
  `"Patriotism is not about loyalty to government" - Edward Snowden`,
  `"If patriotism were defined, not as blind obedience to government, not as submissive worship to flags and anthems, but rather as love of one's country, one's fellow citizens (all over the world), as loyalty to the principles of justice and democracy, then patriotism would require us to disobey our government, when it violated those principles." - Howard Zinn`,
  `"Democracy is the right of the 51% to piss in the soup of the 49%" - Gene epstein`,
  `"You’re in the business of unemploying people. If you think that is unfair, go back to school and study something that doesn’t matter."`,
  // `"Everybody benefits from competition and the division of labor, even on a global scale." - me`,
  `"I would sum up my foreign policy as the following: I don't want to run the world. I don't know how to run the world. The Constitution doesn't give me the authority to run the world. We should mind our own business. And I would sum up my domestic policy as the following: I don't want to run your life. I don't know how to run your life. The Constitution doesn't give me the authority to run your life. We should respect individual liberty in this country." - Ron Paul`,
  `"Pay no attention to the man behind the curtain" - The 'wizard' of Oz`,
  // `"It's important to distinguish between terrorism and resisting occupation" - (some Qatar official I think ...at the UN General Assembly General Debate)
  `"We killed hundreds of thousands of people lost thousands of our own, spent a trillion dollars all to eliminate a WMD threat that never existed" - Tucker Carlson on Iraq war`,
  `"...does this mean that individuals will always choose correctly or morally when confronted with choices in life? Of course not. But it is only through a continuous process of having to choose, and of having to bear the consequences of choice, that the individual can ever hope to gain a high sense of conscience, responsibility, and morality" - Jacob G. Hornberger`,
  `"While I have met only a few of the people living on earth during my lifetime, I have yet to encounter a single person who has achieved such mastery over his life and conduct that he is now competent to begin passing judgment over, and reforming, others...then, let us use God’s, not Caesar’s, methods in helping to bring our fellow humans to a life of happiness, peace, and fulfillment" - Jacob G. Hornberger`,
  `"My major difference with conservatives is that they wish to conserve, rather than end, an economic system which is not only evil and immoral but also a denigration of the great God-given gifts of life, liberty, property, and conscience which once constituted the great heritage of this nation." - Jacob G. Hornberger`,
  `"Neoconservatives mistook Tel Aviv for the capital of the United States" - Russel Kirk`,
  `"You'll be holy when you choose not to sin"`,
  `"When we advance governmental solutions, we are imploring the state to limit—via violence—particular voluntary exchanges between informed, consenting adults."`,
  // `"Americans are very good at blowing stuff up. They are less good at understanding where the pieces fall once they're done." - Nikola Tesla (youtuber)
  `"We don't have an adversarial press. We just have a press that actually serves as the PR arm of the government." - George Szamuely`,
  `"America should have minded her own business and stayed out of the world war. If you hadn't entered the war the Allies would have made peace with Germany in the Spring of 1917. If we had made peace then there would have been no collapse in Russia followed by Communism. No breakdown in Italy followed by Fascism. And Germany would not have signed the Versad Treaty, which has enthroned Nazism in Germany." Winston Churchilll 1936`,
  `"You know when Roosevelt signed off on building the Pentagon he had always promised that after it was done he would turn it into a hospital...you know he kept his word. It is. It's the largest insane asylum in the world" - Dan Beragan`,
  `"all government power comes from the barrel of a gun" - Moa sa tung`,
  `"Before you tear down a fence, find out why it was put up in the first place" - Chesterson`,
  `"It's almost like a piece of paper written in the late 1700s doesn't stop tirrants in 2019" - Dave Smith`,
  `"Essentially what we believe is the bullshit that everybody else pretends to believe, but we actually believe it" - Dave Smith`,
  `"The answer to 1984 is 1776"`,
  // `"if you want to know how to make war, ask the general. If you want to know how to make peace, ignore them." - Me`,
  `"At any given moment two out of the three news networks on cable will be talking about Russia and the other one will he talking about the war on Christmas" - Jimmy Dore`,
  `"A Republican is just a Democrat going the speed limit"`,
  `"One death is a tragedy, a million is a statistic" - Stalin`,
  `"The devil was created by God, we must remember that this malice we attribute to his nature is not from creation but deprivation" - John Calvin`,
  // `"Competition creates winners and losers. And consumers are always the winners" - me`
  `"You didn't take out the IRS. You killed Susan in accounting" - Dave Smith`,
  `"I love beers and high-fives as much as anybody" - Owen Benjamin`,
  `"I didn't know I was a slave until I found out I couldn't do the things I wanted." - Frederick Douglass`,
  `"Bill Nye is not a scientist..he's a science propagandist...well that's not a good word.." - Joe Rogan`,
  `"80% of the murders happen on 2% of the streets" - Thomas Sowell`,
  `"We do not choose to be ruled, we elect to be governed" - Barry Goldwater`,
  `"The publication of leaks—particularly leaks that show potential government wrongdoing or human rights abuse-is a critical function of a free press in a democratic society." - Dinah PoKempner June 19, 2018`,
  `"Is it time for a 3rd party? It's time for a second party"`,
  `"We have a one party system. There's two parts of it"`,
  `"we know Saddam Huissen had weapons of mass destruction. We have the receipts."`,
  `"Conservatives are Progressives driving the speed limit"`,
  // `"If he was an American president he'd be on mount Rushmore next to Lincoln" Dave on assad
  //  `"As for Tucker Carlson, he arguably cemented his position as the country’s most high-profile critic of America’s wars."
  //The guardian
  //https://amp.theguardian.com/us-news/2018/apr/13/syria-intervention-conservative-rightwing-opposition-trump

  `"Old cold war McCarthyism has become new warm war fantasy politics". - Jim Kavanagh`,
  // `"Negro men are being sent 8000 miles away to secure liberties in South East Asia that they haven't found in Southwest Georgia
  `"laws are opinions enforced at gunpoint. If there is no victim, there is no crime. If there is no crime, the law is criminal" - Jacob Tothe`,
  `"Thank God we don't get as much governemnt as we pay for"`,
  // `"if you had $100 and you wanted to help people, where would you send your money? To the federal welfare system or the Salvation Army?" - Rand Paul
  // `"if it's not a 'hell yes' than it's a no."
  `"More aggression, assault, and robbery has been done in the name of law than in spite of law" - Larken Rose`,
  `"The terrorist threat is a predictable consequence of meddling in the affairs of others" - Ron Paul`,
  `"I'm speaking with myself number one, because I have a very good brain" - Donald Trump`,
  `"The evidence of the strength of your beliefs is the strength of your actions" - Ed Snowden`,
  `"that's why we can't have governments. We aren't morally equipped for all the secrets and force" - Dave Smith`,
  `"It's not dev ops if you can't burn it down"`,
  `"I want gay married couples to be able to defend their weed farms with guns"`,
  `"I happen to believe that individuals are more apt to act responsibly and morally than institutions ever do."`,
  `"He had a massive stroke of good fortune and he's in a better place now" - Andy`,
  // `"He is both a creature and a creation of the media and the media will never own that, Michael Moore on Donald Trump`,
  `"Just because you know the mechanics of how something works doesn't make it any less of a miracle" Bill (True Blood)`,
  `"Liberals should bear in mind that the first identity movement in American politics was the Ku Klux Klan, which still exists. Those who play the identity game should be prepared to lose it." - Mark Lilla, The New York Times`,
  `"I want multi-ethnic gay polygamist families in South Africa to be able to sell cocaine and submachine guns to a transgender Jewish nudist colony in Alabama. Or to be able to refuse to make that transaction based on their moral principles." - Pvtt_dancer`,
  `“Shouldn’t we finish up the two wars we have before we look for another?” - Robert Gates`,
  `“dropping bombs on someone to prove that you’re willing to drop bombs on someone is just about the worst reason to use force.” - Barack Obama`,
  `"Elections are not held for social participation" - Thomas Sowell`,
  `"Libertarianism is the most progressive philosophy" - Ron Paul`,
  `"Saying that you don't care about privacy because you have nothing to hide is no different than saying you don't care about freedom of speech because you have nothing to say" - Edward Snowden`,
  `"The law cannot protect us...it's up to people to protect the laws" - Edward Snowden`,
  `"Every government interferes with every other government's elections every time. That's what they do. That's why you pay intelligence services." - Edward Snowden`,
  `"The bottom line is I found evidence again and again as I went deeper and deeper levels of government, higher and higher clearances that these programs were never about terrorism; that's the public justification for them. They're about economic espionage, they're about diplomatic manipulation, they're about social influence, they're about power." - Edward Snowden`,
  `"You are who you are in secret" - Dekota Marsh`,
  `"When I studied science in school it was...open to all knowledge...But now it has become closed minded to much of life, the part that can't be explained in material means. It is foreign to me now, though I continue to study it daily." -Lakett`,
  `"I won't waste my time trying to convince a skeptical, scientific, logical mind that an entire universe didn't just pop into existence without cause or reason. Lawrence Krauss just doesn't explain it, nor can you. We can. Sure you gotta believe in the invisible evil Jehovah, but you know that's just a little more of a stretch than believing in a multiverse that cannot be proven or relying on unknown quantum mechanics for your existence." -Jonathan Brooks`,
  `“Our Constitution was made only for a moral and religious people. It is wholly inadequate to be the government of any other.” -President John Adams`,
  `"impossible is nothing"`,
  `"You know all the chickens had a common ancestor?...It was a chicken." - Dr. Kent Hovind`,
  `"The issue shouldn't be if the Lord's on my side, the issue should be that I am on the Lord's side" -Abraham Lincoln`,
  `"I don't give people hell. I tell the truth, and they think it's hell." -Harry Truman`
]
export const fairQuotes = [
  `"I'm turtle-doggin over here" - Casey Cotter`,
  `"I'm so nervous I just wanna poop everywhere" - Casey Cotter`,
  `"Your challenge has been met, cause with a breath I could snap your neck. This won't be like the first time you tried, cause my patience and mercy for you has run dry" -Oh Sleeper`,
  `"I don't know what to do with these long elbows of mine" - Casey Cotter`,
  `"Man there isn't a lot of tread left. I'm out for sure" - Jacob Stangl`,
  `"No one is going to respect you if you're a broke loser with no skills"`,
  `"You live in a country where criminal threats of violence stop political speech now, and the only people trying to stop that is the ****** the racists. How do you feel about that?" - Christopher Cantwell`,
  `"The left demands violence and the right meets market demand" - Christopher Cantwell`,
  `"Who gets raises? Hands. Hands in cookie jars" - Casey Cotter`,
  `"Being a racist today is recognizing differences between racial groups so yeah, I'm a racist and every honest person is a racist" - Christopher Cantwell`,
  `"The last capitalist we hang will be the one who sold us the rope" - Marx`,
  `"We have no compassion and we ask no compassion from you. When our time comes we will make no excuse for the terror" - Marx`,
  `"You can define communism as the abolition of private property" - Marx`,
  `"We citizens are being duped and are nothing but cash cows financing a bloated devious criminal enterprise known as the federal government" - FamQ Dbltap`,
  `"Obama broke their principles and Trump broke their brain" - Dave Smith`,
  `"That's why I could never be a leftist. I like to laugh too much" - Mance Rader`,
  `"The idea that anybody can get a vote on your freedom is disgusting" - Dave Smith`,
  `"It's not about kissing trump's ass, it's about kissing the truth's butt" - Alex Jones`,
  `"Many pro-life advocates were horrified by the sweeping and controversial new abortion laws in New York and other states. Many pro-choice advocates were horrified by the sweeping and controversial new abortion laws in Alabama and other states. Yet in all of these states, the laws were passed with strong local support."`,
  `"I care not what puppet is placed upon the throne of England to rule the Empire on which the sun never sets. The man who controls Britains's money supply controls the British Empire, and I control the British money supply" - Nathan Rothschild`,
  `"I love liberty and hate equality" - John Randolph`,
  `"Americans tend to use words like 'stand' and 'fight' in their political disagreements, although they never really have to stand and fight for anything. Tank Man stood and fought. The Hong Kong protesters stand and fight. We take to social media and argue." - David Harsanyi`,
  `"The game of basketball is great. That's what gives one guy corn and the other guy a fireplace" - Dave Smith`,
  `"They are not after me they are after you I am just in the way" - Donald Trump.`,
  `“An anti-Semite used to mean a man who hated Jews. Now it means a man who is hated by Jews.” - Joseph Sobran`,
  `"military force is a very blunt and not very effective instrument to promote human rights" - Stephen Zunes`,
  `"MSNBC will tell you the exact opposite of reality" - lefty Jimmy Dore`,
  `"Trump is the first president in a generation that sought to end wars rather than start them" - Rand Paul`,
  `"If you give people what they want, they will tolerate being ruled" - Dave Smith`,
]

export default [
  // ...fairQuotes,
  // ...goodQuotes,
  ...goodQuotes,
  ...greatQuotes,
  ...greatQuotes,
  ...greatQuotes,
  ...greatQuotes,
  ...greatQuotes,
];
